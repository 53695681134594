// ParentComponent.js
import React from "react";
import LearnerActivity from "./LearnerActivity"; // Import the LearnerActivity component
const ParentComponent = () => {
    // Create a sample enrolledLearner object (replace with your actual data)
    const enrolledLearner = {
        accessible_resources: [1, 2, 3, 4],
        course_id: 1,
        learner_id: 1,
        learner_name: "Gururaj",
        // Add any other relevant properties here...
    };
    // Pass the enrolledLearner object as a prop to LearnerActivity
    return <LearnerActivity enrolledLearner={enrolledLearner} />;
};
export default ParentComponent;
import React, { Fragment, useEffect, useRef, useState } from "react";
import { getResponseGet, getResponsePost } from "../lib/utils";
import * as d3 from "d3";
import JourneyMap from "./JourneyMap";
import HexModule from "./Hexmodule";
import ButtonPanel from "./ButtonPanel";
import LearnerPositionComponent from "./LearnerPositionComponent";
import LearnerAtResource from "./LearnerAtResource";
import { useNavigate,BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ResourceMenu from "./ResourceMenu";
import Alert from '@mui/material/Alert';
import CreateQuiz from "./CreateQuiz";
import Snackbar from '@mui/material/Snackbar';
// Grid component
export const GridComponent = ({ width, height, step }) => {
  const lines = [];
  for (let x = 0; x <= width; x += step) {
    lines.push(
      <line
        key={`v${x}`}
        x1={x}
        y1={0}
        x2={x}
        y2={height}
        stroke="lightgrey"
        strokeWidth="1"
      />
    );
  }
  for (let y = 0; y <= height; y += step) {
    lines.push(
      <line
        key={`h${y}`}
        x1={0}
        y1={y}
        x2={width}
        y2={y}
        stroke="lightgrey"
        strokeWidth="1"
      />
    );
  }
  return <>{lines}</>;
};

// SVG component with zoom
export const SVGComponent = ({
  children,
  width,
  height,
  svgRef,
  zoomRef,
  setTransform,
  transform,
  isUpdateActive,
  setNewPos,
  handleUpdate,
}) => {
  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const g = svg.select("g");

    const zoom = d3
      .zoom()
      .scaleExtent([0.6, 10])
      .on("start", (event) => {
        // Check if the zoom event was triggered by dragging
        // console.log("On drag its triggered")
        if (
          event.sourceEvent &&
          event.sourceEvent.type === "mousedown" &&
          event.sourceEvent.type !== "dblclick"
        ) {
          console.log(event.sourceEvent.type);
          svg.style("cursor", "move"); // Set cursor to 'move' on drag start
        }
      })
      .on("zoom", (event) => {
        g.attr("transform", event.transform);
        setTransform(event.transform);
      })
      .on("end", (event) => {
        svg.style("cursor", "default");
      });
    // console.log("this si the zoom factor", zoom);
    const initialTransform = d3.zoomIdentity.translate(130, 10).scale(1.2);
    svg.call(zoom.transform, initialTransform); // Apply initial zoom
    g.attr("transform", initialTransform); // Set initial transform on the group
    setTransform(initialTransform);
    svg.call(zoom);
    zoomRef.current = zoom;
  }, []);
  // console.log("svg ref",svgRef)

  // Handle SVG click to get position
  const handleSvgClick = (event) => {
    console.log("isupdate", isUpdateActive);
    if (!isUpdateActive) return;

    const svg = d3.select(svgRef.current);
    const point = d3.pointer(event, svg.node());
    const transformedPoint = transform.invert(point); // Convert to the zoomed coordinates

    // Perform an action with the coordinates
    console.log("Clicked position:", transformedPoint);
    // alert(`Clicked at: X=${transformedPoint[0]}, Y=${transformedPoint[1]}`);
    setNewPos({ x: transformedPoint[0], y: transformedPoint[1] });
    // open popup
    handleUpdate();
  };
  return (
    <svg
      ref={svgRef}
      style={{ width: "100%", height: "100%" }}
      onClick={handleSvgClick}
    >
      <g>
        <GridComponent width={1000} height={1000} step={50} />
        {children}
      </g>
    </svg>
  );
};

// Group component
export const GroupComponent = ({ children }) => {
  return <>{children}</>;
};

export const CircleComponent = ({
  data,
  activitiesState,
  tooltipRef,
  learnerPosState,
  coverageRadius,
  transform,
  enrollId,
  // courseId = 1,
  xScale,
  yScale,
  // isDrag,
  svgRef,
  zoomRef,
  setNewPos,
  // updatePosition,
  // setShowConfirm,
  enrolledLearner,
  setEnrolledLearner,
  handleActivateUpdate,
  handleDeactivateUpdate,
  setAlertOpen,
}) => {
  const learnerId = enrolledLearner?.learner_id;
  const courseId = enrolledLearner?.course_id;
  console.log("her is hi", enrolledLearner);
  /*const [quizCompleted, setQuizCompleted] = useState(false);
  const [totalScore, setTotalScore] = useState(null);  // Use state to manage totalScore after quiz
  const [isCorrect, setIsCorrect] = useState([]);*/
  const navigate = useNavigate(); // Initialize useNavigate hook
  // console.log("data is in circle component",data)

  const [menuPosition, setMenuPosition] = useState(null);

  function updateMenuPosition(newtransform, originalMenuPosition) {
    // Adjust the menu's x and y positions based on the zoom and pan transformation
    // const adjustedX = originalMenuPosition.x * newtransform.k + newtransform.x;
    // const adjustedY = originalMenuPosition.y * newtransform.k + newtransform.y;

    const adjustedX = transform.invertX(originalMenuPosition.x);
    const adjustedY = transform.invertY(originalMenuPosition.y - 80);

    // Update menu position state
    setMenuPosition({ x: adjustedX, y: adjustedY });
    // setMenuPosition(originalMenuPosition);
  }

  const handleGroupClick = (event) => {
    console.log(event, "............");
    // Set menu position and show menu at the click location
    const userType = localStorage.getItem("type");
    // (userType === "TEACHER" || (userType === "LEARNER" && isWithinCoverage)) &&
    if (userType === "TEACHER") {
      // setMenuPosition({ x: event.clientX, y: event.clientY });
      // const originalMenuPosition = { x: event.screenX, y: event.screenY };
      const originalMenuPosition = { x: event.clientX, y: event.clientY };
      updateMenuPosition(transform, originalMenuPosition);
    }
  };

  const handleClose = () => setMenuPosition(null);

  const handleClick = async (event) => {
    const clickedElement = event.target.tagName.toLowerCase();
    if (isWithinCoverage) {
      console.log('LOLOLA: ' + data.description)
      //console.log("LAMGAO:"+data.link);
      if (data.type == 2) {
        event.preventDefault();
         // Initialize quiz data in sessionStorage
      sessionStorage.setItem("enrollId", enrollId); 
      sessionStorage.setItem("courseId", courseId); 
      sessionStorage.setItem("quizTitle", data.name);
      sessionStorage.setItem("quizDesc", data.description);
      const quiz_id = parseInt(data.link, 10);  
      sessionStorage.setItem("quizId", quiz_id);
      sessionStorage.setItem("aType", 1);

        // Fetch quiz questions data
        const response = await getResponseGet(`/quiz_questions/${quiz_id}`);
        // if (!response.ok) {   
        //   throw new Error(`Failed to fetch quiz questions: ${response.statusText}`);
        // }
        const quizData =  response?.data;

        // Navigate to the quiz route and pass only serializable data (no functions)
        // navigate("/quiz", { state: { quizData } });
        activitiesState[1]((activities) => [
          ...activities,
          {
            type: "resource",
            name: data.name,
            link: data.link,
            time: new Date().toString(),
            resource_id: data.id,
            x: data.x,
            y: data.y,
          },
        ]);

        let activityData = {
          type: "resource",
          name: data.name,
          link: data.link,
          time: new Date().toISOString().slice(0, 19).replace("T", " "),
          enroll_id: enrollId,
          resource_id: data.id,
          x_coordinate: data.x,
          y_coordinate: data.y,
        };

        const response2 = await getResponsePost("/activities", activityData);
        console.log(response2);
        navigate("/quiz", {
          state: { quizData }
            });
      } else {

        let data_to_send = {
          resource_id: data.id,
          enroll_id: enrollId,
        };
        // // setLoading(true);
        const response2 = await getResponsePost("/watchResource", data_to_send);
        console.log(response2);
        var newPositions = response2?.data;

        // // setPrevPos(learnerPosState[0]);/
        learnerPosState[1]([newPositions[0], newPositions[1]]);
        console.log("this is the new position", newPositions);
        console.log("this is the new ", learnerPosState[0]);

        const response3 = await getResponseGet(`enrolledLearner/${learnerId}/${courseId}`);// add course id afterwards
        if (response3?.data) {

          setEnrolledLearner(response3.data);
          // console.log("Enrolled Learner", enrolledLearner);
          // Update learner position state with the new coordinates if they exist
          if (response3.data.x_coordinate && response2.data.y_coordinate) {
            learnerPosState[1]([
              Number(response3.data.x_coordinate),
              Number(response3.data.y_coordinate)
            ]);
          }
        } else {
          console.error("Failed to fetch enrolled learner data", response2);
        }
        let circleElement = null;
        if (clickedElement === "circle") {
          // The clicked element is already the circle
          circleElement = event.target;
        } else if (clickedElement === "text") {
          // The clicked element is the text, find the sibling circle
          const parentAnchor = event.target.closest("a");
          circleElement = parentAnchor.querySelector("circle");
        }

        if (circleElement) {
          // Change the fill color of the circle
          circleElement.setAttribute("fill", "orange");
        }

        activitiesState[1]((activities) => [
          ...activities,
          {
            type: "resource",
            name: data.name,
            link: data.link,
            time: new Date().toString(),
            resource_id: data.id,
            x: data.x,
            y: data.y,
          },
        ]);

        let activityData = {
          type: "resource",
          name: data.name,
          link: data.link,
          time: new Date().toISOString().slice(0, 19).replace("T", " "),
          enroll_id: enrollId,
          resource_id: data.id,
          x_coordinate: data.x,
          y_coordinate: data.y,
        };

        const response = await getResponsePost("/activities", activityData);
        console.log(response);
      }
    } else {
      setAlertOpen(true);
      event.preventDefault();
    }
  };

  const handleMouseOver = (event) => {
    console.log(event);
    const tooltip = d3.select(tooltipRef.current);
    tooltip
      .style("visibility", "visible")
      .html(
        `<div>
          <strong>Index:</strong> ${data.index}<br>
          <strong>Name:</strong> ${data.name}<br>
          <strong>Position:</strong> ${data.x.toFixed(3)},${data.y.toFixed(3)}
        </div>`
      )

      .style("left", `${event.pageX}px`)
      .style("top", `${event.pageY - 125}px`);

    d3.select(`#text-${data.index}`).style("visibility", "hidden");
  };

  const handleMouseMove = (event) => {
    const tooltip = d3.select(tooltipRef.current);
    tooltip
      .style("left", `${event.pageX}px`)
      .style("top", `${event.pageY - 125}px`);
  };

  const handleMouseOut = () => {
    const tooltip = d3.select(tooltipRef.current);
    tooltip.style("visibility", "hidden");

    d3.select(`#text-${data.index}`).style("visibility", "visible");
  };
  useEffect(() => {
    const tooltip = d3.select(tooltipRef.current);
    tooltip.style("visibility", "hidden");
  }, [transform.k, tooltipRef]); // Only trigger when transform.k changes

  const visitedResourceIds = (activitiesState[0] || []).map(
    (activity) => activity.resource_id
  );
  const isVisited = visitedResourceIds.includes(data.id);

  const isWithinCoverage = (enrolledLearner?.accessible_resources || []).includes(
    data.id
  );
  const inverseScale = Math.min(1 / transform.k, 1.1);

  return (
    <>
      {localStorage.getItem("type") === "TEACHER" ? (
        <>
          <g onClick={handleGroupClick}>
            <circle
              cx={xScale(data.x)}
              cy={yScale(data.y)}
              r={15 * inverseScale}
              fill={
                isVisited ? "orange" : isWithinCoverage ? "#D1E9F6" : "white"
              }
              stroke="black"
              strokeWidth={2 * inverseScale}
              onMouseOver={handleMouseOver}
              onMouseMove={handleMouseMove}
              onMouseOut={handleMouseOut}
            />
            <text
              x={xScale(data.x)}
              y={yScale(data.y)}
              fill={data.type == 2 ? "red" : "black"} // Change color to red for 'Quiz' module
              fontSize={`${15 * inverseScale}px`}
              fontFamily="FontAwesome"
              textAnchor="middle"
              dominantBaseline="middle"
              onMouseOver={handleMouseOver}
              onMouseMove={handleMouseMove}
              onMouseOut={handleMouseOut}
            >
              {data.type == 2 ? "\uf059" : "\uf16a"}{" "}
              {/* Conditional rendering of icons */}
            </text>
          </g>
          <ResourceMenu
            data={data}
            menuPosition={menuPosition}
            rType={data.type == 2 ? "Quiz" : "Resource"}
            handleClose={handleClose}
            handleActivateUpdate={handleActivateUpdate}
            handleDeactivateUpdate={handleDeactivateUpdate}
            isWithinCoverage={isWithinCoverage}
            inverseScale={inverseScale}
          />
        </>
      ) : (
        <a
          href={isWithinCoverage ? data.link : "#"}
          target={isWithinCoverage ? "_blank" : ""}
          rel="noopener noreferrer"
        >
          <circle
            cx={xScale(data.x)}
            cy={yScale(data.y)}
            r={15 * inverseScale}
            fill={isVisited ? "orange" : isWithinCoverage ? "#D1E9F6" : "white"}
            stroke="black"
            strokeWidth={2 * inverseScale}
            onClick={handleClick}
            onMouseOver={handleMouseOver}
            onMouseMove={handleMouseMove}
            onMouseOut={handleMouseOut}
          />
          <text
            x={xScale(data.x)}
            y={yScale(data.y)}
            fill={data.type == 2 ? "red" : "black"} // Change color to red for 'Quiz' module
            fontSize={`${15 * inverseScale}px`}
            fontFamily="FontAwesome"
            textAnchor="middle"
            dominantBaseline="middle"
            onMouseOver={handleMouseOver}
            onMouseMove={handleMouseMove}
            onMouseOut={handleMouseOut}
            onClick={handleClick}
          >
            {data.type == 2 ? "\uf059" : "\uf16a"}{" "}
            {/* Conditional rendering of icons */}
          </text>
        </a>
      )}
    </>
  );
};

export const ModuleCircleComponent = ({
  moduleData,
  activitiesState,
  tooltipRef,
  transform,
  xScale,
  yScale,
}) => {
  // const enrollId=localStorage.getItem(enrollId);

  const handleMouseOver = (event) => {
    const tooltip = d3.select(tooltipRef.current);
    tooltip
      .style("visibility", "visible")
      .html(
        `<div>
					<strong>Index:</strong> ${moduleData.module_id}<br>
					<strong>Name:</strong> ${moduleData.module}<br>
          <strong>Position:</strong> ${moduleData.x.toFixed(
          3
        )},${moduleData.y.toFixed(3)}
				</div>`
      )
      .style("left", `${event.pageX}px`)
      .style("top", `${event.pageY - 125}px`);

    // Hide the default text
    d3.select(`#text-${moduleData.id}`).style("visibility", "hidden");
  };

  const handleMouseMove = (event) => {
    const tooltip = d3.select(tooltipRef.current);
    tooltip
      .style("left", `${event.pageX}px`)
      .style("top", `${event.pageY - 125}px`);
  };

  const handleMouseOut = () => {
    const tooltip = d3.select(tooltipRef.current);
    tooltip.style("visibility", "hidden");

    // Restore the default text
    d3.select(`#text-${moduleData.id}`).style("visibility", "visible");
  };

  // Hide the tooltip when zooming (transform.k changes)
  useEffect(() => {
    const tooltip = d3.select(tooltipRef.current);
    tooltip.style("visibility", "hidden");
  }, [transform.k, tooltipRef]); // Only trigger when transform.k changes

  const inverseScale = Math.min(1 / transform.k, 1.1);

  return (
    <g>
      <circle
        cx={xScale(moduleData.x)}
        cy={yScale(moduleData.y)}
        r={20 * inverseScale}
        fill={"red"}
        stroke="black" // Border color
        strokeWidth={2 * inverseScale} // Border width
        onMouseOver={handleMouseOver}
        onMouseMove={handleMouseMove}
        onMouseOut={handleMouseOut}
      />
    </g>
  );
};

// Resource Connection Line
const LineComponent = ({ startPos, endPos }) => {
  return endPos ? (
    <>
      <defs>
        <marker
          id="arrowhead"
          markerWidth="10"
          markerHeight="7"
          refX="10"
          refY="3.5"
          orient="auto"
        >
          <polygon
            points="0 0, 10 3.5, 0 7"
            fill={startPos.module_id !== endPos.module_id ? "orange" : "blue"}
          />
        </marker>
      </defs>
      <line
        x1={startPos.x * 8000 - 2800}
        y1={3650 - startPos.y * 8000}
        x2={endPos.x * 8000 - 2800}
        y2={3650 - endPos.y * 8000}
        stroke={startPos.module_id !== endPos.module_id ? "orange" : "blue"}
        strokeWidth="2"
        strokeDasharray={
          startPos.module_id !== endPos.module_id ? "5,5" : "none"
        }
        markerEnd="url(#arrowhead)"
      />
    </>
  ) : (
    <Fragment />
  );
};

// Main LearnerMap component
const LearnerMap = ({
  activitiesState,
  learnerPosState,
  svgRef,
  zoomRef,
  enrollId,
  enrolledLearner,
  setEnrolledLearner,
  enrolledLearnersByCourse,
  courseId,
  learnerAtResourcePos,
  setLearnerAtResourcePos,
}) => {
  const [data, setData] = useState([]);
  const [moduleData, setModuleData] = useState([]);
  const [journeyData, setJourneyData] = useState([]);
  const mapRef = useRef(null);
  const [transform, setTransform] = useState(
    d3.zoomIdentity.translate(130, 10).scale(1.2)
  );
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const tooltipRef = useRef(null);
  const [coverageRadius] = useState(300); // Define the coverage radius (adjust as needed)
  const inverseScale = Math.min(1 / transform.k, 1.1);
  const [showJourney, setShowJourney] = useState(false);
  const [showHex, setShowHex] = useState(false);
  const [showAllLearners, setShowAllLearners] = useState(false);
  const [showModules, setShowModules] = useState(true);
  const [showResources, setShowResources] = useState(true);
  // console.log("eeenrolled learner", enrolledLearner)
  // console.log("all leraners", enrolledLearnersByCourse)
  let dimensionScale = {
    width: 1000,
    height: 1000,
  };

  const loadLearnerAtResource = () => {
    const lastResourceVisitedId = activitiesState[0].at(-1)?.resource_id;
    const lastResourceVisited = data.find(
      (item) => item.id === lastResourceVisitedId
    );
    setLearnerAtResourcePos([lastResourceVisited?.x, lastResourceVisited?.y]);
    console.log("laearner at resource", lastResourceVisited);
    console.log("at resource pos is ", learnerAtResourcePos);
  };

  const xAccessor = (d) => Number(d.x);
  const yAccessor = (d) => Number(d.y);

  dimensionScale.ctrWidth = 1000;
  dimensionScale.ctrHeight = 1000;

  const xScale = d3
    .scaleLinear()
    .domain(d3.extent(data, xAccessor))
    .range([15, dimensionScale.ctrWidth - 15])
    .clamp(true);

  const yScale = d3
    .scaleLinear()
    .domain(d3.extent(data, yAccessor))
    .range([dimensionScale.ctrHeight - 35, 35])
    .clamp(true);

  const loadData = async (courseId) => {
    const response = await getResponseGet(`/resources/${courseId}`);
    // console.log(response)
    if (response) {
      setData(response.data);
      // console.log(response.data)
      console.log("this issss the data", response.data);
    }
  };
  const loadModuleData = async (courseId) => {
    const response = await getResponseGet(`/moduleData/${courseId}`);
    if (response) {
      setModuleData(response.data);
      console.log("this is the module data", response.data);
    }
  };
  const loadJourney = async () => {
    const response = await getResponseGet(`/contributions/${enrollId}`);
    if (response) {
      setJourneyData(response.data);
      console.log("this is the learney journey data", response.data);
    }
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  useEffect(() => {
    loadData(courseId);
    loadModuleData(courseId);
  }, []);

  useEffect(() => {
    enrollId && loadJourney();
  }, [enrollId]);

  useEffect(
    () => {
      loadLearnerAtResource();
    },
    [activitiesState[0]],
    enrollId
  );

  const updateDimensions = () => {
    if (mapRef.current) {
      setDimensions({
        width: mapRef.current.offsetWidth,
        height: mapRef.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const [x, y] = learnerPosState[0]; // Extract x and y coordinates
    const width = svg.node().getBoundingClientRect().width;
    const height = svg.node().getBoundingClientRect().height;
    svg
      .call(
        zoomRef.current.transform,
        d3.zoomIdentity
          .translate(width / 2, height / 2) // Move the center of the map
          .scale(transform.k)
          .translate(-xScale(x), -yScale(y)) // Translate the map based on the user's position
          .translate(-200, 100)
      );
  }, [learnerPosState[0]]);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const learnerPos = learnerPosState[0];
  const [alertOpen, setAlertOpen] = useState(false);
  return (
    <div
      className="learnerMapBody"
      ref={mapRef}
      style={{ position: "relative" }}
    >
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert
          onClose={handleCloseAlert}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Summarise your learning to access this resource
        </Alert>
      </Snackbar>
      <SVGComponent
        width={dimensions.width}
        height={dimensions.height}
        svgRef={svgRef}
        zoomRef={zoomRef}
        setTransform={setTransform}
        transform={transform}
      >
        <GroupComponent>
          {showHex && (
            <HexModule
              data={data}
              xScale={xScale}
              yScale={yScale}
              inverseScale={inverseScale}
            />
          )}
          {showResources && transform.k > 1 && (
            <>
              {data && data.length > 0 ? (
                data.map((d) => (
                  <React.Fragment key={d.id}>
                    <CircleComponent
                      data={d}
                      activitiesState={activitiesState}
                      tooltipRef={tooltipRef}
                      learnerPosState={learnerPosState}
                      coverageRadius={coverageRadius}
                      transform={transform}
                      enrollId={enrollId}
                      xScale={xScale}
                      yScale={yScale}
                      enrolledLearner={enrolledLearner}
                      setEnrolledLearner={setEnrolledLearner}
                      svgRef={svgRef}
                      zoomRef={zoomRef}
                      setAlertOpen={setAlertOpen}

                    />
                    <text
                      id={`text-${d.index}`}
                      x={xScale(d.x) - 5 * inverseScale}
                      y={yScale(d.y) - 20 * inverseScale}
                      fill="black"
                      fontSize={`${12 * inverseScale}px`}
                      fontFamily="sans-serif"
                    >
                      {d.index}
                    </text>
                    {/* Uncomment and modify this as needed */}
                    {/* <LineComponent
                      startPos={d}
                      endPos={d.id < data.length - 1 ? data[d.id + 1] : null}
                    /> */}
                  </React.Fragment>
                ))
              ) : (
                <div>Loading Data...</div>
              )}
              {learnerAtResourcePos && learnerAtResourcePos.length > 0 && (
                <LearnerAtResource
                  pos={learnerAtResourcePos}
                  transform={transform}
                  tooltipRef={tooltipRef}
                  xScale={xScale}
                  yScale={yScale}
                />
              )}
            </>
          )}
          {showModules && transform.k <= 1 && (
            <>
              {moduleData.map((d) => (
                <React.Fragment key={d.module_id}>
                  <ModuleCircleComponent
                    moduleData={d}
                    activitiesState={activitiesState}
                    tooltipRef={tooltipRef}
                    // learnerPos={learnerPos}
                    // coverageRadius={coverageRadius}
                    transform={transform}
                    xScale={xScale}
                    yScale={yScale}
                  />
                  <text
                    id={`text-${d.id}`}
                    x={xScale(d.x) - 25 * inverseScale}
                    y={yScale(d.y) - 30 * inverseScale}
                    fill="black"
                    fontSize={`${15 * inverseScale}px`}
                    fontWeight={25}
                    fontFamily="sans-serif"
                  >
                    {`${d.module.slice(0, 10)}...`}
                  </text>
                  {/* add line component */}
                  {/* <LineComponent
                startPos={d}
                endPos={d.id < data.length - 1 ? data[d.id + 1] : null}
              /> */}
                </React.Fragment>
              ))}
            </>
          )}
          {enrolledLearnersByCourse.length > 0 ? (
            showAllLearners ? (
              enrolledLearnersByCourse.map((d) => (
                <React.Fragment key={d.enroll_id}>
                  <LearnerPositionComponent
                    learnerPosState={learnerPosState}
                    coverageRadius={coverageRadius}
                    transform={transform}
                    tooltipRef={tooltipRef}
                    xScale={xScale}
                    yScale={yScale}
                    enrolledLearner={d}
                    enrolledLearnersByCourse={enrolledLearnersByCourse}
                  />
                </React.Fragment>
              ))
            ) : (
              <LearnerPositionComponent
                learnerPosState={learnerPosState}
                coverageRadius={coverageRadius}
                transform={transform}
                tooltipRef={tooltipRef}
                xScale={xScale}
                yScale={yScale}
                enrolledLearner={enrolledLearner} // This would be the specific learner data to display
              />
            )
          ) : (
            <div>Loading or no data available</div>
          )}

          {journeyData && showJourney && (
            <JourneyMap
              journeyData={journeyData}
              tooltipRef={tooltipRef}
              transform={transform}
              xScale={xScale}
              yScale={yScale}
            />
          )}
        </GroupComponent>
      </SVGComponent>
      <ButtonPanel
        setShowHex={setShowHex}
        setShowJourney={setShowJourney}
        svgRef={svgRef}
        xScale={xScale}
        yScale={yScale}
        learnerPosState={learnerPosState}
        zoomRef={zoomRef}
        transform={transform}
        setShowAllLearners={setShowAllLearners}
        setShowModules={setShowModules}
        setShowResources={setShowResources}
      />

      <div
        ref={tooltipRef}
        style={{
          position: "absolute",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          border: "1px solid #ccc",
          padding: "8px",
          borderRadius: "4px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          pointerEvents: "none",
          visibility: "hidden",
          transition: "opacity 0.2s ease",
          fontSize: "12px",
          zIndex: 10,
        }}
      />
    </div>
  );
};

export default LearnerMap;

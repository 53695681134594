import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { getResponsePost, getResponseGet } from "../lib/utils";
import Spinner from 'react-bootstrap/Spinner';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
// import { pdfjs } from 'react-pdf';
// import Tooltip from '@mui/material/Tooltip';

// Update workerSrc to use the local worker script
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
// 	'pdfjs-dist/build/pdf.worker.min.mjs',
// 	import.meta.url,
// ).toString();




const LearnerSummary = ({ activitiesState, learnerPosState, showSummary, setShowSummary, setOpen, setPrevPos, enrollId, setSummary, summary, learnerId, setEnrolledLearner, courseId }) => {
	// const [pdfFile, setPdfFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [alertOpen, setAlertOpen] = useState(false);

	// const handleFileChange = (event) => {
	// 	const file = event.target.files[0];
	// 	if (file && file.type === 'application/pdf') {
	// 		setPdfFile(file);
	// 		extractTextFromPDF(file);
	// 	} else {
	// 		alert('Please upload a valid PDF file');
	// 	}
	// };


	// const extractTextFromPDF = async (file) => {
	// 	const fileReader = new FileReader();
	// 	fileReader.onload = async () => {
	// 		const typedArray = new Uint8Array(fileReader.result);
	// 		const pdf = await pdfjs.getDocument({ data: typedArray }).promise;
	// 		let text = '';
	// 		for (let i = 1; i <= pdf.numPages; i++) {
	// 			const page = await pdf.getPage(i);
	// 			const content = await page.getTextContent();
	// 			text += content.items.map(item => item.str).join(' ');
	// 		}
	// 		setSummary(text);
	// 	};
	// 	fileReader.readAsArrayBuffer(file);
	// };

	const countWords = (text) => {
		return text.trim().split(/\s+/).length;
	};

	const handleCloseAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertOpen(false);
	};

	const updatePosition = async () => {
		const wordCount = countWords(summary);

		// Check if word count is greater than 100
		if (wordCount < 100) {
			setAlertOpen(true);
			return;
		}

		let data = {
			summary: summary,
			enroll_id: enrollId,
		};
		setLoading(true);
		const response = await getResponsePost("/submitsummary", data);
		console.log(response);
		// Extract position and contribution_id from the response
		const { position, contribution_id } = response?.data || {};
		// Check if position exists
		if (!position) {
			console.log("This is not the expected response:", response?.data);
			return;
		}

		// Log and use the extracted values
		console.log("New Position:", position);
		console.log("Contribution ID:", contribution_id);

		var newPositions = position;
		setPrevPos(learnerPosState[0]);
		learnerPosState[1]([newPositions[0], newPositions[1]]);

		const response2 = await getResponseGet(`enrolledLearner/${learnerId}/${courseId}`);// add course id afterwards
		if (response2?.data) {

			setEnrolledLearner(response2.data);
			// console.log("Enrolled Learner", enrolledLearner);
			// Update learner position state with the new coordinates if they exist
			if (response2.data.x_coordinate && response2.data.y_coordinate) {
				learnerPosState[1]([
					Number(response2.data.x_coordinate),
					Number(response2.data.y_coordinate)
				]);
			}
		} else {
			console.error("Failed to fetch enrolled learner data", response2);
		}


		setSummary(summary);
		activitiesState[1]((activities) => [
			...activities,
			{
				type: "summary",
				name: summary,
				time: Date(),
				contribution_id: contribution_id,
				x: newPositions[0],
				y: newPositions[1],
			},
		]);
		const activityData = {
			type: "summary",
			name: summary.slice(0, 50),
			time: new Date().toISOString().slice(0, 19).replace('T', ' '),
			link: null,
			enroll_id: enrollId,
			contribution_id: contribution_id,
			x_coordinate: newPositions[0],
			y_coordinate: newPositions[1],
		}
		const response1 = await getResponsePost("/activities", activityData);
		console.log(response1);
		const responseData = response1?.data;

		setLoading(false);
		setSummary("");
		setShowSummary(false);
		setOpen(true);
	};

	return (
		<>
			<Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
				<Alert
					onClose={handleCloseAlert}
					severity="error"
					variant="filled"
					sx={{ width: '100%' }}
				>
					Summary should contain atleast 100 words.
				</Alert>
			</Snackbar>
			{showSummary && <div className="learnerSummaryBody" >
				{loading ?
					<>
						<div className="center">
							<Spinner animation="border" style={{ color: "blueviolet" }} />
							<p style={{ marginTop: "13px" }}>Please wait while we update your position</p>

						</div>


					</>
					:
					<>
						<InputGroup className="mb-3 titleText">
							<InputGroup.Text>Title</InputGroup.Text>
							<Form.Control as="textarea" aria-label="With textarea" placeholder="Enter the title" />
						</InputGroup>
						<InputGroup className="mb-3 summaryText">
							<InputGroup.Text>Summary</InputGroup.Text>
							<Form.Control
								as="textarea"
								placeholder="Enter the summary or upload a pdf"
								aria-label="With textarea"
								value={summary}
								maxLength={1500}
								onChange={(e) => setSummary(e.target.value)}
							/>
						</InputGroup>

						{/* <InputGroup className="mb-3 pdfUpload">
      			<Form.Control
        			type="file"
        			accept="application/pdf"
        			onChange={handleFileChange}
      			/>
      			<InputGroup.Text id="basic-addon2">Upload your PDF</InputGroup.Text>
    		</InputGroup> */}

						<Button

							// variant="danger"
							className="summarySubmitButton"
							onClick={updatePosition}
							disabled={loading}
						>
							Update My Position
						</Button>
					</>
				}


			</div>}
		</>


	);
};

export default LearnerSummary;

export const coursesData = [
    {
      id: 1,
      title: "Introduction to Mechanical Engineering",
      description:
        "An overview of the principles and practices of mechanical engineering, including thermodynamics, fluid mechanics, and material science.",
      professor: "Dr. John Smith",
      hours: 40,
      images: [
        "https://cdn.dummyjson.com/products/images/furniture/Annibale%20Colombo%20Bed/1.png",
        "https://cdn.dummyjson.com/products/images/furniture/Annibale%20Colombo%20Bed/2.png",
        "https://cdn.dummyjson.com/products/images/furniture/Annibale%20Colombo%20Bed/3.png",
      ],
      thumbnail:
        "https://cdn.dummyjson.com/products/images/furniture/Annibale%20Colombo%20Bed/thumbnail.png",
    },
    {
      id: 2,
      title: "Electrical Circuits and Systems",
      description:
        "A study of electrical circuits, signal processing, and system analysis with applications in electrical engineering.",
      professor: "Prof. Jane Doe",
      hours: 45,
      images: [
        "https://cdn.dummyjson.com/products/images/furniture/Annibale%20Colombo%20Sofa/1.png",
        "https://cdn.dummyjson.com/products/images/furniture/Annibale%20Colombo%20Sofa/2.png",
        "https://cdn.dummyjson.com/products/images/furniture/Annibale%20Colombo%20Sofa/3.png",
      ],
      thumbnail:
        "https://cdn.dummyjson.com/products/images/furniture/Annibale%20Colombo%20Sofa/thumbnail.png",
    },
    {
      id: 3,
      title: "Civil Engineering Design",
      description:
        "Focuses on the design and analysis of civil engineering projects, including structures, roads, and water systems.",
      professor: "Dr. Emily Zhang",
      hours: 50,
      images: [
        "https://cdn.dummyjson.com/products/images/furniture/Bedside%20Table%20African%20Cherry/1.png",
        "https://cdn.dummyjson.com/products/images/furniture/Bedside%20Table%20African%20Cherry/2.png",
        "https://cdn.dummyjson.com/products/images/furniture/Bedside%20Table%20African%20Cherry/3.png",
      ],
      thumbnail:
        "https://cdn.dummyjson.com/products/images/furniture/Bedside%20Table%20African%20Cherry/thumbnail.png",
    },
    {
      id: 4,
      title: "Chemical Process Engineering",
      description:
        "Covers the fundamentals of chemical engineering, including process design, reaction engineering, and thermodynamics.",
      professor: "Prof. Michael Brown",
      hours: 42,
      images: [
        "https://cdn.dummyjson.com/products/images/furniture/Knoll%20Saarinen%20Executive%20Conference%20Chair/1.png",
        "https://cdn.dummyjson.com/products/images/furniture/Knoll%20Saarinen%20Executive%20Conference%20Chair/2.png",
        "https://cdn.dummyjson.com/products/images/furniture/Knoll%20Saarinen%20Executive%20Conference%20Chair/3.png",
      ],
      thumbnail:
        "https://cdn.dummyjson.com/products/images/furniture/Knoll%20Saarinen%20Executive%20Conference%20Chair/thumbnail.png",
    },
    {
      id: 5,
      title: "Computer-Aided Engineering",
      description:
        "Introduction to computer-aided design (CAD) and simulation tools used in engineering for modeling and analysis.",
      professor: "Dr. Lisa White",
      hours: 38,
      images: [
        "https://cdn.dummyjson.com/products/images/groceries/Cooking%20Oil/1.png",
      ],
      thumbnail:
        "https://cdn.dummyjson.com/products/images/groceries/Cooking%20Oil/thumbnail.png",
    },
    {
      id: 6,
      title: "Thermodynamics and Heat Transfer",
      description:
        "Explores the principles of thermodynamics and heat transfer, with applications in mechanical and chemical engineering.",
      professor: "Dr. David Green",
      hours: 44,
      images: [
        "https://cdn.dummyjson.com/products/images/groceries/Chicken%20Meat/1.png",
        "https://cdn.dummyjson.com/products/images/groceries/Chicken%20Meat/2.png",
      ],
      thumbnail:
        "https://cdn.dummyjson.com/products/images/groceries/Chicken%20Meat/thumbnail.png",
    },
    {
      id: 7,
      title: "Structural Analysis",
      description:
        "Covers the analysis of structures such as beams, trusses, and frames, including stress and strain calculations.",
      professor: "Prof. Sarah Lee",
      hours: 48,
      images: [
        "https://cdn.dummyjson.com/products/images/groceries/Cat%20Food/1.png",
      ],
      thumbnail:
        "https://cdn.dummyjson.com/products/images/groceries/Cat%20Food/thumbnail.png",
    },
    {
      id: 8,
      title: "Materials Science and Engineering",
      description:
        "Introduction to the properties, behavior, and applications of engineering materials, including metals, ceramics, and polymers.",
      professor: "Dr. Robert Johnson",
      hours: 40,
      images: [
        "https://cdn.dummyjson.com/products/images/groceries/Beef%20Steak/1.png",
      ],
      thumbnail:
        "https://cdn.dummyjson.com/products/images/groceries/Beef%20Steak/thumbnail.png",
    },
    {
      id: 9,
      title: "Control Systems Engineering",
      description:
        "Study of the principles and design of control systems, with applications in robotics, automation, and mechanical systems.",
      professor: "Prof. Karen Wilson",
      hours: 46,
      images: ["https://cdn.dummyjson.com/products/images/groceries/Apple/1.png"],
      thumbnail:
        "https://cdn.dummyjson.com/products/images/groceries/Apple/thumbnail.png",
    },
    {
      id: 10,
      title: "Environmental Engineering",
      description:
        "Focuses on the application of engineering principles to environmental problems, including water treatment, waste management, and pollution control.",
      professor: "Dr. Alice Thompson",
      hours: 50,
      images: [
        "https://cdn.dummyjson.com/products/images/furniture/Knoll%20Saarinen%20Executive%20Conference%20Chair/1.png",
        "https://cdn.dummyjson.com/products/images/furniture/Knoll%20Saarinen%20Executive%20Conference%20Chair/2.png",
        "https://cdn.dummyjson.com/products/images/furniture/Knoll%20Saarinen%20Executive%20Conference%20Chair/3.png",
      ],
      thumbnail:
        "https://cdn.dummyjson.com/products/images/furniture/Knoll%20Saarinen%20Executive%20Conference%20Chair/thumbnail.png",
    },
  ];
  
import React, { useEffect, useState } from "react";
import "./Auth.css";
import { getResponsePost } from "../lib/utils";
import { useNavigate } from "react-router-dom";

function Signup({ setIsLoggedIn }) {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [uType, setUtype] = useState(null);
  const [retypePassword, setRetypePassword] = useState("");
  const [email, setEmail] = useState("");
  let storageUsername = localStorage.getItem("username");
  const navigate = useNavigate();
  useEffect(() => {
    if (storageUsername) {
      setIsLoggedIn(true);
    }
  }, []);
  const handleSubmit = async () => {
    console.log("signup", name, email, password, uType);
    if (password !== retypePassword) {
      alert("Passwords do not match");
      return;
    }
    let data =
      uType === "LEARNER"
        ? {
            name: name,
            username: email,
            password: password,
            cgpa: 4.0,
            type: uType,
          }
        : {
            name: name,
            username: email,
            password: password,
            type: uType,
          };
    const response = await getResponsePost("/signup", data);
    console.log(response);
    const responseData = response?.data;
    if (responseData) {
      setIsLoggedIn(true);
      console.log("this is the data from server for signup", responseData);

      localStorage.setItem("username", responseData.username);
      localStorage.setItem("name", responseData.name);
      uType === "LEARNER" && localStorage.setItem("cgpa", responseData.cgpa);
      localStorage.setItem("id", responseData.id);
      localStorage.setItem("type", uType);
    }
    navigate("/");
  };
  return (
    <div className="auth-form">
      <h2>Sign Up</h2>
      <div>
        <input
          type="text"
          placeholder="Name"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <select
          placeholder="User Type"
          required
          onChange={(e) => {
            setUtype(e.target.value);
          }}
          value={uType}
        >
          <option value={null}>Select Type</option>
          <option value="LEARNER">Learner</option>
          <option value="TEACHER">Teacher</option>
        </select>
        <input
          type="password"
          placeholder="Password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          required
          value={retypePassword}
          onChange={(e) => setRetypePassword(e.target.value)}
        />
        <button type="submit" onClick={handleSubmit}>
          Sign Up
        </button>
      </div>
    </div>
  );
}

export default Signup;

import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  Navigate,
} from "react-router-dom";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import "./App.css";
import Dashboard from "./Components/Dashboard";
import UserDashboard from "./Pages/UserDashboard";
import SummaryExamples from "./Components/SummaryExamples";
import PolylinesList from "./Components/PolylinesList";
import Quiz from "./Components/Quiz";  // Import the Quiz component
import CreateQuiz from "./Components/CreateQuiz"; // Import the CreateQuiz component
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons'
import TeacherDashboard from "./Pages/TeacherDashboard";
import TeacherNavigation from "./Pages/TeacherNavigation";
library.add(faLocationCrosshairs);

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Set to false initially for actual use
  const [courseId, setCourseId] = useState(0);
  const [enrolledPolylines, setEnrolledPolylines] = useState([]);
  const [topicData, setTopicData] = useState([]);

  return (
    <Router>
      <div className="app">
        {!isLoggedIn ? (
          <div className="auth-card">
            <div className="auth-nav">
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Sign In
              </NavLink>
              <NavLink
                to="/signup"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Sign Up
              </NavLink>
            </div>
            <Routes>
              <Route
                path="/"
                element={<Login setIsLoggedIn={setIsLoggedIn} />}
              />
              <Route
                path="/signup"
                element={<Signup setIsLoggedIn={setIsLoggedIn} />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        ) : localStorage.getItem("type")==="LEARNER" ? (
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route
              path="/course"
              element={
                <Dashboard
                  setIsLoggedIn={setIsLoggedIn}
                  courseId={courseId}
                  setCourseId={setCourseId}
                  topicData={topicData}
                  setTopicData={setTopicData}
                  setEnrolledPolylines={setEnrolledPolylines}
                />
              }
            />
            <Route
              path="/dashboard"
              element={<UserDashboard setCourseId={setCourseId} />}
            />
            <Route path="/summary-examples" element={<SummaryExamples />} />
            <Route
              path="/polylines-list"
              element={
                <PolylinesList
                  enrolledPolylines={enrolledPolylines}
                  topicData={topicData}
                />
              }
            />
            <Route path="/quiz" element={<Quiz />} />
            {/* <Route path="/create-quiz" element={<CreateQuiz />} /> Add the CreateQuiz route */}
          </Routes>
        ) : localStorage.getItem("type")==="TEACHER" ? (
          <Routes>
            <Route path="/" element={<Navigate to="/t/dashboard" />} />
            <Route
              path="/t/dashboard"
              element={<TeacherDashboard setCourseId={setCourseId} />}
            />
            <Route
              path="/t/course"
              element={
                <TeacherNavigation
                  setIsLoggedIn={setIsLoggedIn}
                  courseId={courseId}
                  setCourseId={setCourseId}
                  topicData={topicData}
                  setTopicData={setTopicData}
                  setEnrolledPolylines={setEnrolledPolylines}
                />
              }
            />
            <Route path="/create-quiz" element={<CreateQuiz />} /> 
            {/* Add the CreateQuiz route */}
          </Routes>
        ) : (
          <></>
        )}
      </div>
    </Router>
  );
}

export default App;





// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar {
    display: flex;
    flex-direction: row;
    padding: 16px;
    width: 100%;
    background: #ccc;
    align-items: center;
    gap: 16px;
}

.intro {
    padding: 8px; 
    flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/Pages/UserDashboard/css/UserInfo.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".bar {\n    display: flex;\n    flex-direction: row;\n    padding: 16px;\n    width: 100%;\n    background: #ccc;\n    align-items: center;\n    gap: 16px;\n}\n\n.intro {\n    padding: 8px; \n    flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// import React from "react";
import api from "./axios";

export const getResponsePost = async (url, data, headers) => {
    try {
        const response = await api.post(url, data, {
            headers: headers,
        });
        return response;
    } catch (err) {
        if (err.response?.status === 404) {
            localStorage.clear();
            window.location.reload();
        }
        return err;
    }
};

export const getResponseGet = async (url, headers, params) => {
    try {
        const response = await api.get(url, {
            headers,
            params
        });
        return response;
    } catch (err) {
        if (err.response?.status === 404) {
        }
        return err;
    }
};

export const getResponseDelete = async (url, headers) => {
    try {
        const response = await api.delete(url, {
            headers: headers,
        });
        return response;
    } catch (err) {
        return err;
    }
};

export const getResponsePut = async (url, data, headers) => {
    try {
        const response = await api.put(url, JSON.stringify(data), {
            headers: headers,
        });
        return response;
    } catch (err) {
        return err;
    }
};

export const getWordCount = (str) => {
    return str === null
        ? 0 : str.split(/\s+/).filter(word => word.length > 0).length
};

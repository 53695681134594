import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { getResponseGet } from "../../../lib/utils";
import {
  CircleComponent,
  GroupComponent,
  ModuleCircleComponent,
  SVGComponent,
} from "../../../Components/LearnerMap";
import HexModule from "../../../Components/Hexmodule";
import ButtonPanel from "../../../Components/ButtonPanel";
import JourneyMap from "../../../Components/JourneyMap";
import Learners from "./Learners";
import UpdatePositionPopup from "../../../Components/UpdatePositionPopup";
import LearnerActivity from "../../../Components/LearnerActivity";
const TeacherMap = ({
  activitiesState,
  learnerPosState,
  svgRef,
  zoomRef,
  enrollId,
  enrolledLearner,
  enrolledLearnersByCourse,
  courseId,
}) => {
  const [data, setData] = useState([]);
  const [moduleData, setModuleData] = useState([]);
  const [journeyData, setJourneyData] = useState([]);
  const mapRef = useRef(null);
  const [transform, setTransform] = useState(
    d3.zoomIdentity.translate(130, 10).scale(0.6)
  );
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const tooltipRef = useRef(null);
  const [coverageRadius] = useState(300); // Define the coverage radius (adjust as needed)
  const inverseScale = Math.min(1 / transform.k, 1.1);
  const [showJourney, setShowJourney] = useState(false);
  const [showHex, setShowHex] = useState(false);
  const [showAllLearners, setShowAllLearners] = useState(true);
  const [showModules, setShowModules] = useState(true);
  const [showResources, setShowResources] = useState(true);
  const [isDrag, setIsDrag] = useState(false);

  const [newPos, setNewPos] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isUpdateActive, setIsUpdateActive] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [updatingData, setUpdatingData] = useState(null);

  // console.log("eeenrolled learner", enrolledLearner)
  // console.log("all leraners", enrolledLearnersByCourse)
  let dimensionScale = {
    width: 1000,
    height: 1000,
  };

  const xAccessor = (d) => Number(d.x);
  const yAccessor = (d) => Number(d.y);

  dimensionScale.ctrWidth = 1000;
  dimensionScale.ctrHeight = 1000;

  const xScale = d3
    .scaleLinear()
    .domain(d3.extent(data, xAccessor))
    .range([15, dimensionScale.ctrWidth - 15])
    .clamp(true);

  const yScale = d3
    .scaleLinear()
    .domain(d3.extent(data, yAccessor))
    .range([dimensionScale.ctrHeight - 35, 35])
    .clamp(true);

  const loadData = async (courseId) => {
    const response = await getResponseGet(`/resources/${courseId}`);
    // console.log(response)
    if (response) {
      setData(response.data);
      // console.log(response.data)
      console.log("this issss the data", response.data);
    }
  };
  const loadModuleData = async (courseId) => {
    const response = await getResponseGet(`/moduleData/${courseId}`);
    if (response) {
      setModuleData(response.data);
      console.log("this is the module data", response.data);
    }
  };
  const loadJourney = async () => {
    const response = await getResponseGet(`/contributions/${enrollId}`);
    if (response) {
      setJourneyData(response.data);
      console.log("this is the learney journey data", response.data);
    }
  };
  useEffect(() => {
    if (refresh) {
      loadData(courseId);
      loadModuleData(courseId);
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    enrollId && loadJourney();
  }, [enrollId]);

  const updateDimensions = () => {
    if (mapRef.current) {
      setDimensions({
        width: mapRef.current.offsetWidth,
        height: mapRef.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const learnerPos = learnerPosState[0];

  

  const handleActivateUpdate = (data) => {
    setIsUpdateActive(true);
    setUpdatingData(data);
  };

  const handleDeactivateUpdate = () => {
    setIsUpdateActive(false);
    setUpdatingData(null);
    setNewPos(null);
  };

  const handleConfirmPopup = () => setShowConfirm(true);

  useEffect(() => {
    if (!showConfirm) {
      // setNewPos(null);
      handleDeactivateUpdate();
    }
  }, [showConfirm]);

  return (
    <div
      className="learnerMapBody"
      ref={mapRef}
      style={{ position: "relative" }}
    >
      <SVGComponent
        width={dimensions.width}
        height={dimensions.height}
        svgRef={svgRef}
        zoomRef={zoomRef}
        setTransform={setTransform}
        transform={transform}
        isUpdateActive={isUpdateActive}
        setNewPos={setNewPos}
        handleUpdate={handleConfirmPopup}
      >
        <GroupComponent>
          {showHex && (
            <HexModule
              data={data}
              xScale={xScale}
              yScale={yScale}
              inverseScale={inverseScale}
            />
          )}
          {showResources && transform.k > 1 && (
            <>
              {data.length > 0 ? (
                data.map((d) => (
                  <React.Fragment key={d.id}>
                    <CircleComponent
                      data={d}
                      activitiesState={activitiesState}
                      tooltipRef={tooltipRef}
                      learnerPos={learnerPos}
                      coverageRadius={coverageRadius}
                      transform={transform}
                      enrollId={enrollId}
                      xScale={xScale}
                      yScale={yScale}
                      enrolledLearner={enrolledLearner}
                      // isDrag={isDrag}
                      svgRef={svgRef}
                      zoomRef={zoomRef}
                      //   updatePosition={updatePosition}
                      // setNewPos={setNewPos}
                      // setShowConfirm={setShowConfirm}
                      handleActivateUpdate={handleActivateUpdate}
                      handleDeactivateUpdate={handleDeactivateUpdate}
                    />
                    <text
                      id={`text-${d.index}`}
                      x={xScale(d.x) - 5 * inverseScale}
                      y={yScale(d.y) - 20 * inverseScale}
                      fill="black"
                      fontSize={`${12 * inverseScale}px`}
                      fontFamily="sans-serif"
                    >
                      {d.index}
                    </text>
                    {/* Uncomment and modify this as needed */}
                    {/* <LineComponent
          startPos={d}
          endPos={d.id < data.length - 1 ? data[d.id + 1] : null}
        /> */}
                  </React.Fragment>
                ))
              ) : (
                <div>Loading Data...</div>
              )}
            </>
          )}
          {showModules && transform.k <= 1 && (
            <>
              {moduleData &&
                moduleData.length > 0 &&
                moduleData.map((d) => (
                  <React.Fragment key={d.module_id}>
                    <ModuleCircleComponent
                      moduleData={d}
                      activitiesState={activitiesState}
                      tooltipRef={tooltipRef}
                      learnerPos={learnerPos}
                      coverageRadius={coverageRadius}
                      transform={transform}
                      xScale={xScale}
                      yScale={yScale}
                    />
                    <text
                      id={`text-${d.id}`}
                      x={xScale(d.x) - 25 * inverseScale}
                      y={yScale(d.y) - 30 * inverseScale}
                      fill="black"
                      fontSize={`${15 * inverseScale}px`}
                      fontWeight={25}
                      fontFamily="sans-serif"
                    >
                      {`${d.module.slice(0, 10)}...`}
                    </text>
                    {/* add line component */}
                    {/* <LineComponent
                  startPos={d}
                  endPos={d.id < data.length - 1 ? data[d.id + 1] : null}
                /> */}
                  </React.Fragment>
                ))}
            </>
          )}

          {enrolledLearnersByCourse.length > 0 ? (
            showAllLearners ? (
              enrolledLearnersByCourse.map((d) => (
                <React.Fragment key={d.enroll_id}>
                  <Learners
                    learnerPosState={learnerPosState}
                    coverageRadius={coverageRadius}
                    transform={transform}
                    tooltipRef={tooltipRef}
                    xScale={xScale}
                    yScale={yScale}
                    enrolledLearner={d}
                    enrolledLearnersByCourse={enrolledLearnersByCourse}
                    activitiesState={activitiesState}
                  />
                </React.Fragment>
              ))
            ) : (
              <Learners
                learnerPosState={learnerPosState}
                coverageRadius={coverageRadius}
                transform={transform}
                tooltipRef={tooltipRef}
                xScale={xScale}
                yScale={yScale}
                enrolledLearner={enrolledLearner} // This would be the specific learner data to display
              />
            )
          ) : (
            <div>Loading or no data available</div>
          )}

          {journeyData && showJourney && (
            <JourneyMap
              journeyData={journeyData}
              tooltipRef={tooltipRef}
              transform={transform}
              xScale={xScale}
              yScale={yScale}
            />
          )}
        </GroupComponent>
      </SVGComponent>
      <ButtonPanel
        setShowHex={setShowHex}
        setShowJourney={setShowJourney}
        svgRef={svgRef}
        xScale={xScale}
        yScale={yScale}
        learnerPosState={learnerPosState}
        zoomRef={zoomRef}
        transform={transform}
        setShowAllLearners={setShowAllLearners}
        setShowModules={setShowModules}
        setShowResources={setShowResources}
        isDrag={isDrag}
        setIsDrag={setIsDrag}
      />

      <div
        ref={tooltipRef}
        style={{
          position: "absolute",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          border: "1px solid #ccc",
          padding: "8px",
          borderRadius: "4px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          pointerEvents: "none",
          visibility: "hidden",
          transition: "opacity 0.2s ease",
          fontSize: "12px",
          zIndex: 10,
        }}
      />

      <UpdatePositionPopup
        open={showConfirm}
        setOpen={setShowConfirm}
        newPos={newPos}
        oldPos={{ x: xScale(data.x), y: yScale(data.y) }}
        xScale={xScale}
        yScale={yScale}
        // updatePosition={updatePosition}
        data={updatingData}
        setRefresh={setRefresh}
      />
    </div>
  );
};

export default TeacherMap;

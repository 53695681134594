import React, { useRef } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';

const CustomFileInput = ({
    onChange,
    isInvalid,
    accept = '.pdf',
    label = 'Choose file',
    invalidFeedback = 'Please choose a valid file',
    ...buttonProps
}) => {
    const fileInputRef = useRef(null);

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        console.log(file);
        onChange(e);
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div>
            <InputGroup className="d-flex">
                <Form.Control
                    type="text"
                    value={label}
                    disabled
                    className={`mr-2 ${isInvalid ? 'is-invalid' : ''}`}
                    />
                <Button
                    variant="outline-secondary"
                    onClick={handleButtonClick}
                    {...buttonProps}
                >
                    Browse
                </Button>
            </InputGroup>
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileSelect}
                accept={accept}
                style={{ display: 'none' }}
            />
            {isInvalid && (
                <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                    {invalidFeedback}
                </Form.Control.Feedback>
            )}
        </div>
    );
};

export default CustomFileInput;
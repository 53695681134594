import React, { useState } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    NavLink,
    Navigate,
    Link,
} from "react-router-dom";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import "./App.css";
import Dashboard from "./Components/Dashboard";
import UserDashboard from "./Pages/UserDashboard";
import SummaryExamples from "./Components/SummaryExamples";
import PolylinesList from "./Components/PolylinesList";
import Quiz from "./Components/Quiz";
import CreateQuiz from "./Components/CreateQuiz";
import TADashboard from "./Pages/TADashboard";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";
import TeacherDashboard from "./Pages/TeacherDashboard";
import TeacherNavigation from "./Pages/TeacherNavigation";
import TANavigation from "./Pages/TANavigation";

import Page404 from "./Pages/Page404";
import AddCourse from "./Pages/AddCourse";
import QuizDetails from "./Components/QuizDetails";
import ParentComponent from "./Components/ParentComponent";

library.add(faLocationCrosshairs);

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [courseId, setCourseId] = useState(0);
    const [enrolledPolylines, setEnrolledPolylines] = useState([]);
    const [topicData, setTopicData] = useState([]);
    const [enrolledLearner, setEnrolledLearner] = useState({
        accessible_resources: [1, 2, 3, 4],
        course_id: 1,
        learner_id: 1,
        learner_name: "Gururaj",
    });

    return (
        <Router>
            <div className="app">
                {!isLoggedIn ? (
                    <div className="auth-card">
                        <div className="auth-nav">
                            <NavLink
                                to="/"
                                className={({ isActive }) => (isActive ? "active" : "")}
                            >
                                Sign In
                            </NavLink>
                            <NavLink
                                to="/signup"
                                className={({ isActive }) => (isActive ? "active" : "")}
                            >
                                Sign Up
                            </NavLink>
                        </div>
                        <Routes>
                            <Route
                                path="/"
                                element={<Login setIsLoggedIn={setIsLoggedIn} />}
                            />
                            <Route
                                path="/signup"
                                element={<Signup setIsLoggedIn={setIsLoggedIn} />}
                            />
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </div>
                ) : localStorage.getItem("type") === "LEARNER" ? (
                    <Routes>
                        <Route path="/" element={<Navigate to="/dashboard" />} />
                        <Route
                            path="/course"
                            element={
                                <Dashboard
                                    setIsLoggedIn={setIsLoggedIn}
                                    courseId={courseId}
                                    setCourseId={setCourseId}
                                    topicData={topicData}
                                    setTopicData={setTopicData}
                                    setEnrolledPolylines={setEnrolledPolylines}
                                />
                            }
                        />
                        <Route
                            path="/dashboard"
                            element={<UserDashboard setCourseId={setCourseId} setIsLoggedIn={setIsLoggedIn} />}
                        />
                        <Route path="/summary-examples" element={<SummaryExamples />} />
                        <Route
                            path="/polylines-list"
                            element={
                                <PolylinesList
                                    enrolledPolylines={enrolledPolylines || localStorage.getItem("enrolledPolylines")}
                                    topicData={topicData}
                                />
                            }
                        />
                        <Route path="/quiz" element={<Quiz />} />
                        {/* <Route path="/create-quiz" element={<CreateQuiz />} /> Add the CreateQuiz route */}
                        <Route path="/quiz/attempt/:userId" element={<QuizDetails />} />


                        {/* Render ParentComponent here for Learner */}
                        <Route
                            path="/learner-activity"
                            element={<ParentComponent enrolledLearner={enrolledLearner} />}
                        />
                        <Route path="*" element={<Page404 />} />
                    </Routes>
                ) : localStorage.getItem("type") === "TEACHER" ? (
                    <Routes>
                        <Route path="/" element={<Navigate to="/t/dashboard" />} />
                        <Route
                            path="/t/dashboard"
                            element={<TeacherDashboard setCourseId={setCourseId} setIsLoggedIn={setIsLoggedIn} />}
                        />
                        <Route path="/quiz" element={<Quiz />} />

                        <Route
                            path="/t/add-course"
                            element={
                                <AddCourse />
                            }
                        />
                        <Route
                            path="/t/course"
                            element={
                                <TeacherNavigation
                                    setIsLoggedIn={setIsLoggedIn}
                                    courseId={courseId}
                                    setCourseId={setCourseId}
                                    topicData={topicData}
                                    setTopicData={setTopicData}
                                    setEnrolledPolylines={setEnrolledPolylines}
                                />
                            }
                                />
                                <Route path="/polylines-list" element={
                                    <PolylinesList
                                        enrolledPolylines={enrolledPolylines || localStorage.getItem("enrolledPolylines")}
                                        topicData={topicData} />} />

                        <Route path="/create-quiz" element={<CreateQuiz />} />
                        {/* Add the CreateQuiz route */}
                        <Route path="*" element={<Page404 />} />
                    </Routes>
                ): localStorage.getItem("type") === "TA" ? (
                    <Routes>
                        {/* BUGGY CODE FIX THIS */}
                        <Route path="/" element={<Navigate to="/TA/dashboard" />} />
                        <Route path="/TA/dashboard" element={<TADashboard setCourseId={setCourseId} setIsLoggedIn={setIsLoggedIn} />} />
                        {/* <Route path="/course" element={<Dashboard setIsLoggedIn={setIsLoggedIn} courseId={courseId} setCourseId={setCourseId} topicData={topicData} setTopicData={setTopicData} setEnrolledPolylines={setEnrolledPolylines} />} /> */}
                        <Route path="/TA/course" element={<TANavigation
                                    setIsLoggedIn={setIsLoggedIn}
                                    courseId={courseId}
                                    setCourseId={setCourseId}
                                    topicData={topicData}
                                    setTopicData={setTopicData}
                                    setEnrolledPolylines={setEnrolledPolylines}/>}/>
                        {/* <Route path="/polylines-list" element={<PolylinesList enrolledPolylines={enrolledPolylines} topicData={topicData} />} /> */}
                        <Route path="/polylines-list" element={
                                    <PolylinesList
                                        enrolledPolylines={enrolledPolylines || localStorage.getItem("enrolledPolylines")}
                                        topicData={topicData} />} />

                        <Route path="/create-quiz" element={<CreateQuiz />} />
                        <Route path="/quiz/attempt/:userId" element={<QuizDetails />} />
                        <Route path="/ta-activity" element={<ParentComponent enrolledLearner={enrolledLearner} />} />
                        <Route path="/quiz" element={<Quiz />} />
                        <Route path="*" element={<Page404 />} />
                    </Routes>

                )
                : (
                    <Routes>
                        <Route path="*" element={<Page404 />} />
                    </Routes>
                )}
            </div>
        </Router>
    );
}

export default App;

import React, { useState } from "react";
import { getResponseGet } from "../../../lib/utils.js";

export default function useGetUnassignedCourses() {
  const [unassignedCourses, setUnassignedrCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  // const teacherId = localStorage.getItem("id");
  const getUnassignedCourseData = async () => {
    setLoading(true);
    // setTimeout(setData(coursesData), 1000);
    const response = await getResponseGet(`teacher/courses/unassigned`);
    // const response=null
    if (response?.data) {
      console.log("courses", response.data);
      setUnassignedrCourses(response.data);
    } else {
      console.error("Failed to fetch courses", response);
    }
    setLoading(false);
  };

  return { unassignedCourses, loading, getUnassignedCourseData };
}

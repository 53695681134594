// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-course {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}

.navbar {
    padding: 16px;
    background: #ccc;
    font-size: 24pt;
    display: flex;
    flex-direction: row;
}

.footer {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px;
    background: #ccc;
}`, "",{"version":3,"sources":["webpack://./src/Pages/AddCourse/css/AddCourse.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".add-course {\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n    width: 100%;\n}\n\n.navbar {\n    padding: 16px;\n    background: #ccc;\n    font-size: 24pt;\n    display: flex;\n    flex-direction: row;\n}\n\n.footer {\n    display: flex;\n    flex-direction: row;\n    gap: 16px;\n    padding: 16px;\n    background: #ccc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react';
import { Button, TextField, Card, CardContent, Typography, Grid, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const CreateQuiz = () => {
  const [quizData, setQuizData] = useState({
    title: '',
    quiz_id: '',
    description: '',
    total_questions: 0,
    module_id: '',
    submodule_id: '',
    course_id: '',
    index: '',
    questions: [],
  });

  // Handler for quiz details
  const handleQuizChange = (e) => {
    const { name, value } = e.target;
    setQuizData({ ...quizData, [name]: value });
  };

  // Handler for individual question details
  const handleQuestionChange = (index, e) => {
    const { name, value } = e.target;
    const updatedQuestions = [...quizData.questions];
    updatedQuestions[index] = { ...updatedQuestions[index], [name]: value };
    setQuizData({ ...quizData, questions: updatedQuestions });
  };

  // Add a new question
  const addQuestion = () => {
    setQuizData({
      ...quizData,
      questions: [
        ...quizData.questions,
        {
          question_id: '',
          question_description: '',
          quiz_id: quizData.quiz_id,
          option_a: '',
          option_b: '',
          option_c: '',
          option_d: '',
          correct_answer: '',
        },
      ],
    });
  };

  // Remove a question
  const removeQuestion = (index) => {
    const updatedQuestions = quizData.questions.filter((_, i) => i !== index);
    setQuizData({ ...quizData, questions: updatedQuestions });
  };

  // Submit the quiz
  const handleSubmit = async () => {
    try {
      // Send the quizData to your backend endpoint
      // Assuming you have a post function to handle this
      // await postData("/api/quiz", quizData);
      console.log('Quiz submitted:', quizData);
      alert('Quiz created successfully!');
    } catch (error) {
      console.error('Error submitting quiz:', error);
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <Typography variant="h4" component="h1" style={{ marginBottom: '20px' }}>Create New Quiz</Typography>
      
      <Card style={{ marginBottom: '20px', padding: '20px' }}>
        <CardContent>
          <Typography variant="h6">Quiz Details</Typography>
          <TextField label="Title" name="title" fullWidth margin="normal" value={quizData.title} onChange={handleQuizChange} />
          <TextField label="Quiz ID" name="quiz_id" fullWidth margin="normal" value={quizData.quiz_id} onChange={handleQuizChange} />
          <TextField label="Description" name="description" fullWidth margin="normal" value={quizData.description} onChange={handleQuizChange} />
          <TextField label="Total Questions" name="total_questions" fullWidth margin="normal" value={quizData.total_questions} onChange={handleQuizChange} />
          <TextField label="Module ID" name="module_id" fullWidth margin="normal" value={quizData.module_id} onChange={handleQuizChange} />
          <TextField label="Submodule ID" name="submodule_id" fullWidth margin="normal" value={quizData.submodule_id} onChange={handleQuizChange} />
          <TextField label="Course ID" name="course_id" fullWidth margin="normal" value={quizData.course_id} onChange={handleQuizChange} />
          <TextField label="Index" name="index" fullWidth margin="normal" value={quizData.index} onChange={handleQuizChange} />
        </CardContent>
      </Card>

      <Typography variant="h6" style={{ marginBottom: '10px' }}>Questions</Typography>
      
      {quizData.questions.map((question, index) => (
        <Card key={index} style={{ marginBottom: '10px', padding: '20px' }}>
          <CardContent>
            <Typography variant="subtitle1">Question {index + 1}</Typography>
            <TextField label="Question ID" name="question_id" fullWidth margin="normal" value={question.question_id} onChange={(e) => handleQuestionChange(index, e)} />
            <TextField label="Question Description" name="question_description" fullWidth margin="normal" value={question.question_description} onChange={(e) => handleQuestionChange(index, e)} />
            <TextField label="Option A" name="option_a" fullWidth margin="normal" value={question.option_a} onChange={(e) => handleQuestionChange(index, e)} />
            <TextField label="Option B" name="option_b" fullWidth margin="normal" value={question.option_b} onChange={(e) => handleQuestionChange(index, e)} />
            <TextField label="Option C" name="option_c" fullWidth margin="normal" value={question.option_c} onChange={(e) => handleQuestionChange(index, e)} />
            <TextField label="Option D" name="option_d" fullWidth margin="normal" value={question.option_d} onChange={(e) => handleQuestionChange(index, e)} />
            <TextField label="Correct Answer" name="correct_answer" fullWidth margin="normal" value={question.correct_answer} onChange={(e) => handleQuestionChange(index, e)} />
            <IconButton color="secondary" onClick={() => removeQuestion(index)}><RemoveCircleIcon /></IconButton>
          </CardContent>
        </Card>
      ))}

      <Button variant="outlined" color="primary" onClick={addQuestion} startIcon={<AddCircleIcon />}>Add Question</Button>

      <div style={{ marginTop: '20px' }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>Submit Quiz</Button>
      </div>
    </div>
  );
};

export default CreateQuiz;

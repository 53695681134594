import React from "react";

const ResourceMenu = ({
  rType,
  data,
  menuPosition,
  handleClose,
  handleActivateUpdate,
  handleDeactivateUpdate,
  handleView,
  isWithinCoverage
}) => {
  const handleChangePosition = () => {
    handleActivateUpdate(data)
    alert("Please click on to the location to update the position")
    handleClose();
  };

  const handleMenuClose = () => {
    handleDeactivateUpdate()
    handleClose();
  };

  const handleViewResource = () => {
    // open link also
    const userType=localStorage.getItem("type")
    // if(userType==="LEARNER" && isWithinCoverage){
    //     handleView();
    // }
    // ||(userType==="LEARNER" && isWithinCoverage)
    if(userType==="TEACHER" ){
        if(data.link){
            window.open(data.link, "_blank", "noopener,noreferrer");
        }else if(rType==="Quiz"){
          alert("show quiz here")
        }
    }
  };

  const tMenu = [
    {
      label: "About Resource",
      action: () => alert("Show popup about the details of the resource"),
    },
    {
      label: "Open Resource",
      action: handleViewResource,
    },
    {
      label: "Change Position",
      action: handleChangePosition,
    },
  ];
  const lMenu = [
    {
      label: "About Resource",
      action: () => alert("Show popup about the details of the resource"),
    },
    {
      label: "Open Resource",
      action: handleViewResource,
    },
  ];

  return (
    <>
      {menuPosition && (
        <foreignObject
          x={menuPosition.x}
          y={menuPosition.y}
          //   width={150}
          //   height={120}
          width={140}
          height={150}
        >
          <div
            style={{
              // display: "inline-block",
              background: "white",
              border: "1px solid black",
              borderRadius: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "10px",
              color: "blue",
              zIndex: 1100, // Ensuring it appears on top
              fontFamily: "Arial, sans-serif",
              // maxWidth: "200px", // Optional maximum width
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                cursor: "pointer",
                fontWeight: "bold",
                color: "#333",
              }}
              onClick={handleMenuClose} // Close action
            >
              &times;
            </div>
            <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
              {localStorage.getItem("type") === "TEACHER"
                ? tMenu.map((item, index) => (
                    <li
                      key={index}
                      onClick={item.action}
                      style={{
                        padding: "8px 12px",
                        cursor: "pointer",
                        color: "#333",
                        borderBottom: "1px solid #ddd",
                        transition: "background-color 0.2s ease",
                        fontSize: "12px",
                        textDecoration: "none",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#f5f5f5")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                    >
                      {item.label}
                    </li>
                  ))
                : lMenu.map((item, index) => (
                    <li
                      key={index}
                      onClick={item.action}
                      style={{
                        padding: "8px 12px",
                        cursor: "pointer",
                        color: "#333",
                        borderBottom: "1px solid #ddd",
                        transition: "background-color 0.2s ease",
                        fontSize: "12px",
                        textDecoration: "none",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#f5f5f5")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                    >
                      {item.label}
                    </li>
                  ))}
            </ul>
          </div>
        </foreignObject>
      )}
    </>
  );
};

export default ResourceMenu;

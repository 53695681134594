import React, { useEffect, useState } from "react";
import "./Auth.css";
import { getResponsePost } from "../lib/utils";
import { useNavigate } from "react-router-dom";

function Signup({ setIsLoggedIn }) {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [uType, setUtype] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [email, setEmail] = useState("");
  
  const navigate = useNavigate();
  
  useEffect(() => {
    const storageUsername = localStorage.getItem("username");
    if (storageUsername) {
      setIsLoggedIn(true);
      navigate("/"); // Redirect if already logged in
    }
  }, [setIsLoggedIn, navigate]);

  // const handleSubmit = async () => {
  //   console.log("Signup attempt:", { name, email, password, uType });

  //   if (password !== retypePassword) {
  //     alert("Passwords do not match");
  //     return;
  //   }

  //   if (!uType) {
  //     alert("Please select a user type");
  //     return;
  //   }

  //   let data =
  //     uType === "LEARNER"
  //       ? { name, username: email, password, cgpa: 4.0, type: uType }
  //       : { name, username: email, password, type: uType };

  //   try {
  //     const response = await getResponsePost("/signup", data);
  //     console.log("API Response:", response);

  //     const responseData = response?.data;
  //     if (!responseData) {
  //       alert("Signup failed. Please check your input.");
  //       return;
  //     }

  //     console.log("Signup successful:", responseData);

  //     // Store user data
  //     localStorage.setItem("username", responseData.username);
  //     localStorage.setItem("name", responseData.name);
  //     localStorage.setItem("id", responseData.id);
  //     // localStorage.setItem("ta_id", responseData.ta_id);

  //     localStorage.setItem("type", uType);

  //     if (uType === "LEARNER") {
  //       localStorage.setItem("cgpa", responseData.cgpa);
  //     }
  //     if (uType === "TA") {
  //       localStorage.setItem("ta_id", responseData.ta_id);
  //     }


  //     setIsLoggedIn(true);
      
  //     console.log("Navigating to homepage...");
  //     setTimeout(() => navigate("/"), 100); // Ensure smooth transition

  //   } catch (error) {
  //     console.error("Signup error:", error);
  //     alert("An error occurred during signup. Please try again.");
  //   }
  // };

  const handleSubmit = async () => {
    console.log("Signup attempt:", { name, email, password, uType });

    if (password !== retypePassword) {
      alert("Passwords do not match");
      return;
    }

    if (!uType) {
      alert("Please select a user type");
      return;
    }

    let data =
      uType === "LEARNER"
        ? { name, username: email, password, cgpa: 4.0, type: uType }
        : { name, username: email, password, type: uType };

    try {
      const response = await getResponsePost("/signup", data);
      console.log("API Response:", response);

      const responseData = response?.data;
      if (!responseData) {
        alert("Signup failed. Please check your input.");
        return;
      }

      console.log("Signup successful:", responseData);

      // Store user data
      localStorage.setItem("username", responseData.username);
      localStorage.setItem("name", responseData.name);
      localStorage.setItem("id", responseData.id);
      localStorage.setItem("type", uType);

      if (uType === "LEARNER") {
        localStorage.setItem("cgpa", responseData.cgpa);
      }
      if (uType === "TA") {
        if (responseData.ta_id) {
          localStorage.setItem("ta_id", responseData.ta_id);
        } else {
          console.warn("ta_id is missing in signup response.");
        }
      }

      setIsLoggedIn(true);

      console.log("Navigating to homepage...");
      setTimeout(() => navigate("/"), 100); 

    } catch (error) {
      console.error("Signup error:", error);
      alert("An error occurred during signup. Please try again.");
    }
  };

  return (
    <div className="auth-form">
      <h2>Sign Up</h2>
      <div>
        <input
          type="text"
          placeholder="Name"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <select
          required
          onChange={(e) => setUtype(e.target.value)}
          value={uType}
        >
          <option value="">Select Type</option>
          <option value="LEARNER">Learner</option>
          <option value="TEACHER">Teacher</option>
          <option value="TA">TA</option>
        </select>
        <input
          type="password"
          placeholder="Password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          required
          value={retypePassword}
          onChange={(e) => setRetypePassword(e.target.value)}
        />
        <button type="submit" onClick={handleSubmit}>
          Sign Up
        </button>
      </div>
    </div>
  );
}

export default Signup;

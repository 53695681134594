// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* QuizDetails.css */

.quiz-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .quiz-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #007bff;
  }
  
  .quiz-card {
    background-color: #fff;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .quiz-card:hover {
    transform: translateY(-5px);
  }
  
  .quiz-info {
    font-size: 16px;
    color: #555;
    margin: 8px 0;
    text-align: left;
  }
  
  .quiz-info strong {
    color: #007bff;
  }
  
  .quiz-button {
    background-color: #007bff !important;
    color: white !important;
    font-size: 16px !important;
    font-weight: bold !important;
    padding: 10px 20px !important;
    border-radius: 8px !important;
    transition: background-color 0.3s ease;
  }
  
  .quiz-button:hover {
    background-color: #0056b3 !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/QuizDetails.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,yCAAyC;IACzC,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,wCAAwC;IACxC,sCAAsC;EACxC;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,eAAe;IACf,WAAW;IACX,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,oCAAoC;IACpC,uBAAuB;IACvB,0BAA0B;IAC1B,4BAA4B;IAC5B,6BAA6B;IAC7B,6BAA6B;IAC7B,sCAAsC;EACxC;;EAEA;IACE,oCAAoC;EACtC","sourcesContent":["/* QuizDetails.css */\n\n.quiz-container {\n    max-width: 800px;\n    margin: 40px auto;\n    padding: 20px;\n    background-color: #f9f9f9;\n    border-radius: 12px;\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\n    text-align: center;\n  }\n  \n  .quiz-title {\n    font-size: 24px;\n    font-weight: bold;\n    margin-bottom: 20px;\n    color: #007bff;\n  }\n  \n  .quiz-card {\n    background-color: #fff;\n    margin-bottom: 20px;\n    padding: 15px;\n    border-radius: 10px;\n    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);\n    transition: transform 0.3s ease-in-out;\n  }\n  \n  .quiz-card:hover {\n    transform: translateY(-5px);\n  }\n  \n  .quiz-info {\n    font-size: 16px;\n    color: #555;\n    margin: 8px 0;\n    text-align: left;\n  }\n  \n  .quiz-info strong {\n    color: #007bff;\n  }\n  \n  .quiz-button {\n    background-color: #007bff !important;\n    color: white !important;\n    font-size: 16px !important;\n    font-weight: bold !important;\n    padding: 10px 20px !important;\n    border-radius: 8px !important;\n    transition: background-color 0.3s ease;\n  }\n  \n  .quiz-button:hover {\n    background-color: #0056b3 !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import PolylineChart from "./PolylineChart"; // Import the PolylineChart component

const PolylinesList = ({ enrolledPolylines, topicData }) => {
    const [localPolylines, setLocalPolylines] = useState([]);

    console.log("currentCourseId:", localStorage.getItem("currentCourseId"));
    console.log("topicData:", topicData);

    // Get the polylines from localStorage **once** at the start
    const enrolledPolylinesFromLS = JSON.parse(localStorage.getItem("enrolledPolylines")) || [];
    console.log("currentLearner's polyline from LS:", enrolledPolylinesFromLS);

    useEffect(() => {
        if (Array.isArray(enrolledPolylinesFromLS)) {
            setLocalPolylines(enrolledPolylinesFromLS);
        } else {
            console.error("enrolledPolylinesFromLS is not an array:", enrolledPolylinesFromLS);
            setLocalPolylines([]);
        }

        // Remove from localStorage after setting local state
        return () => {
            console.log("Removing enrolledPolylines from localStorage...");
            localStorage.removeItem("enrolledPolylines");
        };
    }, []); // Runs only once when the component mounts

    // Prefer localPolylines over enrolledPolylines
    const polylinesToRender = Array.isArray(localPolylines) && localPolylines.length > 0
        ? localPolylines
        : (Array.isArray(enrolledPolylines) ? enrolledPolylines : []);

    console.log("polylinesToRender is", polylinesToRender);
    console.log("Is polylinesToRender an array?", Array.isArray(polylinesToRender));
    console.log("Type of polylinesToRender is", typeof polylinesToRender);
    console.log("polylinesToRender.length is", polylinesToRender.length);

    return (
        <div className="polylines-container" style={{ padding: "20px", backgroundColor: "#f5f5f5" }}>
            <h1 style={{ textAlign: "center", marginBottom: "20px" }}>My Learning Journey</h1>
            {polylinesToRender.length > 0 ? (
                polylinesToRender.map((polyline, index) => (
                    <div
                        key={index}
                        className="polyline-item"
                        style={{
                            marginBottom: "40px",
                            padding: "20px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                            {index === 0 ? "Initial Polyline" : `After ${index} Contribution`}
                        </h2>
                        {/* Ensure polyline is an array before passing it to the chart */}
                        <PolylineChart polyline={Array.isArray(polyline) ? polyline : []} topicData={topicData} />
                    </div>
                ))
            ) : (
                <p style={{ textAlign: "center", fontSize: "18px", color: "#555" }}>
                    No enrolled polylines found.
                </p>
            )}
        </div>
    );
};

export default PolylinesList;


// import React, { useEffect, useState } from "react";
// import PolylineChart from "./PolylineChart"; // Import the PolylineChart component

// const PolylinesList = ({ enrolledPolylines, topicData }) => {
//     const [localPolylines, setLocalPolylines] = useState([]);

//     console.log("currentCourseId:", localStorage.getItem("currentCourseId"));
//     console.log("topicData:", topicData);

//     // Get the polylines from localStorage **once** at the start
//     const enrolledPolylinesFromLS = JSON.parse(localStorage.getItem("enrolledPolylines")) || [];
//     console.log("currentLearner's polyline from LS:", enrolledPolylinesFromLS);

//     useEffect(() => {
//         // Always use enrolledPolylinesFromLS instead of fetching again
//         if (Array.isArray(enrolledPolylinesFromLS)) {
//             setLocalPolylines(enrolledPolylinesFromLS);
//         } else {
//             console.error("enrolledPolylinesFromLS is not an array:", enrolledPolylinesFromLS);
//             setLocalPolylines([]);
//         }
//     }, []); // Runs only once when the component mounts

//     // Prefer localPolylines over enrolledPolylines
//     const polylinesToRender = Array.isArray(localPolylines) && localPolylines.length > 0
//         ? localPolylines
//         : (Array.isArray(enrolledPolylines) ? enrolledPolylines : []);

//     console.log("polylinesToRender is", polylinesToRender);
//     console.log("Is polylinesToRender an array?", Array.isArray(polylinesToRender));
//     console.log("Type of polylinesToRender is", typeof polylinesToRender);
//     console.log("polylinesToRender.length is", polylinesToRender.length);

//     return (
//         <div className="polylines-container" style={{ padding: "20px", backgroundColor: "#f5f5f5" }}>
//             <h1 style={{ textAlign: "center", marginBottom: "20px" }}>My Learning Journey</h1>
//             {polylinesToRender.length > 0 ? (
//                 polylinesToRender.map((polyline, index) => (
//                     <div
//                         key={index}
//                         className="polyline-item"
//                         style={{
//                             marginBottom: "40px",
//                             padding: "20px",
//                             backgroundColor: "white",
//                             borderRadius: "10px",
//                             boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//                         }}
//                     >
//                         <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
//                             {index === 0 ? "Initial Polyline" : `After ${index} Contribution`}
//                         </h2>
//                         {/* Ensure polyline is an array before passing it to the chart */}
//                         <PolylineChart polyline={Array.isArray(polyline) ? polyline : []} topicData={topicData} />
//                     </div>
//                 ))
//             ) : (
//                 <p style={{ textAlign: "center", fontSize: "18px", color: "#555" }}>
//                     No enrolled polylines found.
//                 </p>
//             )}
//         </div>
//     );
// };

// export default PolylinesList;


// import React from 'react';
// import PolylineChart from './PolylineChart'; // Import the PolylineChart component

// const PolylinesList = ({ enrolledPolylines, topicData }) => {
//     console.log("enrolled polylines list is ", enrolledPolylines);
//     console.log("module data in polylines list is", topicData);

//     // Function to dynamically generate the heading based on the polyline index
//     const getHeading = (index) => {
//         if (index === 0) return "Initial Polyline";
//         return `After ${index}${index === 1 ? 'st' : index === 2 ? 'nd' : index === 3 ? 'rd' : 'th'} Contribution`;
//     };

//     return (
//         <div className="polylines-container" style={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
//             <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>My Learning Journey</h1>
//             {enrolledPolylines.map((polyline, index) => (
//                 <div
//                     key={index}
//                     className="polyline-item"
//                     style={{
//                         marginBottom: '40px',
//                         padding: '20px',
//                         backgroundColor: 'white',
//                         borderRadius: '10px',
//                         boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
//                     }}
//                 >
//                     <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
//                         {getHeading(index)} {/* Dynamically set the heading */}
//                     </h2>
//                     <PolylineChart polyline={polyline} topicData={topicData} />
//                 </div>
//             ))}
//         </div>
//     );
// };

// export default PolylinesList;


// import React, { useEffect, useState } from "react";
// import PolylineChart from "./PolylineChart"; // Import the PolylineChart component

// const PolylinesList = ({ enrolledPolylines, topicData }) => {
//     const [localPolylines, setLocalPolylines] = useState([]);
//     console.log("currentCourseId: ", localStorage.getItem("currentCourseId"));
//     console.log("topicData: ", topicData);
//     const enrolledPolylinesFromLS = JSON.parse(localStorage.getItem("enrolledPolylines"));
//     console.log("currentLearner's polyline from LS: ", enrolledPolylinesFromLS);


//     // Function to dynamically generate the heading based on the polyline index
//     const getHeading = (index) => {
//         if (index === 0) return "Initial Polyline";
//         return `After ${index}${index === 1 ? 'st' : index === 2 ? 'nd' : index === 3 ? 'rd' : 'th'} Contribution`;
//     };

//     useEffect(() => {
//         // Always try to get polyline from localStorage first
//         const storedPolylines = localStorage.getItem("enrolledPolylines");
//         // console.log("Got the Stored Polylines", storedPolylines); // gives type string
//         if (storedPolylines && storedPolylines !== "undefined") {
//             try {
//                 const parsedPolylines = JSON.parse(storedPolylines);

//                 // Ensure it's an array before setting state
//                 if (Array.isArray(parsedPolylines)) {
//                     setLocalPolylines(parsedPolylines);
//                 } else {
//                     console.error("Stored polylines is not an array:", parsedPolylines);
//                     setLocalPolylines([]); // Fallback to empty array
//                 }
//             } catch (error) {
//                 console.error("Error parsing enrolledPolylines from localStorage:", error);
//                 setLocalPolylines([]); // Fallback to empty array
//             }
//         }
//     }, []); // Runs only once when the component mounts

//     // Always prefer localPolylines over enrolledPolylines
//     console.log("local polylines is", localPolylines);
//     const polylinesToRender = Array.isArray(localPolylines) && localPolylines.length > 0
//         ? localPolylines
//         : (Array.isArray(enrolledPolylines) ? enrolledPolylines : []);
//     console.log("polylinesToRender is", polylinesToRender);
//     console.log("Is polylinesToRender an array?", Array.isArray(polylinesToRender));
//     console.log("Type of polylinesToRender is", typeof polylinesToRender);

//     console.log("polylinesToRender.length is", polylinesToRender.length);

//     return (
//         <div className="polylines-container" style={{ padding: "20px", backgroundColor: "#f5f5f5" }}>
//             <h1 style={{ textAlign: "center", marginBottom: "20px" }}>My Learning Journey</h1>
//             {polylinesToRender.length > 0 ? (
//                 polylinesToRender.map((polyline, index) => (
//                     <div
//                         key={index}
//                         className="polyline-item"
//                         style={{
//                             marginBottom: "40px",
//                             padding: "20px",
//                             backgroundColor: "white",
//                             borderRadius: "10px",
//                             boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//                         }}
//                     >
//                         <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
//                             {getHeading(index)}
//                         </h2>
//                         <PolylineChart polyline={polyline} topicData={topicData} />
//                         {/* <PolylineChart polyline={Array.isArray(polyline) ? polyline : []} topicData={topicData} /> */}
//                         {/* <PolylineChart polyline={polylinesToRender} topicData={topicData} /> */}
//                     </div>
//                 ))
//             ) : (
//                 <p style={{ textAlign: "center", fontSize: "18px", color: "#555" }}>
//                     No enrolled polylines found.
//                 </p>
//             )}
//         </div>
//     );
// };

// export default PolylinesList;




import React from 'react';
import PolylineChart from './PolylineChart'; // Import the PolylineChart component

const PolylinesList = ({ enrolledPolylines, topicData }) => {
  console.log("enrolled polylines list is ", enrolledPolylines);
  console.log("module data in polylines list is", topicData);

  // Function to dynamically generate the heading based on the polyline index
  const getHeading = (index) => {
    if (index === 0) return "Initial Polyline";
    return `After ${index}${index === 1 ? 'st' : index === 2 ? 'nd' : index === 3 ? 'rd' : 'th'} Contribution`;
  };

  return (
    <div className="polylines-container" style={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>My Learning Journey</h1>
      {enrolledPolylines.map((polyline, index) => (
        <div
          key={index}
          className="polyline-item"
          style={{
            marginBottom: '40px',
            padding: '20px',
            backgroundColor: 'white',
            borderRadius: '10px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
            {getHeading(index)} {/* Dynamically set the heading */}
          </h2>
          <PolylineChart polyline={polyline} topicData={topicData} />
        </div>
      ))}
    </div>
  );
};

export default PolylinesList;

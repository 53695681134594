export const GRADES=[
    {label:"A",value:1.0},
    {label:"A-",value:0.925},
    {label:"B+",value:0.85},
    {label:"B",value:0.75},
    {label:"B-",value:0.675},
    {label:"C+",value:0.6},
    {label:"C",value:0.5},
    {label:"D",value:0.25},
    {label:"F",value:0},
]
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getResponseGet } from "../lib/utils";

const NavigateToQuizDetails = ({ userId }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleNavigate = async () => {
        setLoading(true);
        setError(null); // Reset error on new click

        try {
            // Call API to fetch quiz logs for the user
            const response = await getResponseGet(`/fetch_quiz_log/${userId}`);
            if (!response.data || response.data.length === 0) {
                throw new Error("No quiz data found for this user.");
            }

            console.log("Quiz Data:", response.data);

            // Navigate to the quiz details page with userId only
            navigate(`/quiz/attempt/${userId}`);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <button
                    onClick={handleNavigate}
                    style={{
                        padding: "10px",
                        fontSize: "16px",
                        backgroundColor: "#1976d2",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        marginTop: "10px",
                    }}
                >
                    View Quiz Details
                </button>
            )}
            {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
    );
};

export default NavigateToQuizDetails;

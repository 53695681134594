// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form {
    flex-grow: 1;
    padding: 16px;
    overflow-y: scroll;
}

.topics-header {
    padding: 16px 0;
    font-size: 16pt;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/AddCourse/css/CourseForm.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,eAAe;AACnB","sourcesContent":[".form {\n    flex-grow: 1;\n    padding: 16px;\n    overflow-y: scroll;\n}\n\n.topics-header {\n    padding: 16px 0;\n    font-size: 16pt;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
